import React from 'react';

const Items = () => {
    return(
        <div>
            Items
        </div>
    );
};

export default Items;