import React from 'react'
import { Link } from 'react-router-dom';
import styles from "./footer.module.css"
//Assets
import PokmeonPic from '../assets/pikachu.png';
import LocationPic from '../assets/pointer.png';
import ItemsPic from '../assets/pokeball.png';

const Footer = () =>{
    return <footer className={styles.footer}>
        <Link onClick={(event) => event.preventDefault} className={styles.footerLink} to="/pokemons">
            <img className={styles.footerIcon} src = {PokmeonPic} alt="Pokeball" />
            Pokemons
        </Link>
        <Link onClick={(event) => event.preventDefault} className={styles.footerLink} to="/items">
            <img className={styles.footerIcon} src = {ItemsPic} alt="Pokeball" />
            Items
        </Link>
        <Link onClick={(event) => event.preventDefault} className={styles.footerLink} to="/location">
            <img className={styles.footerIcon} src = {LocationPic} alt="Pokeball" />
            Map
        </Link>
    </footer>
}


export default Footer;
