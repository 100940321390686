import { useNavigate, useParams } from 'react-router-dom';
import PokeballImg from "../assets/pokeball.png"
import Footer from '../components/Footer';
import BulbasaurImg from '../assets/bulbasaur.gif'
import styles from "./pokemon.module.css"
import { useEffect, useState } from 'react';
import { PokemonDetails } from '../types/types';
import { fetchPokemon } from '../api/fetchPokemon';
import LoadingScreen from '../components/LoadingScreen';

import { waitFor } from '../utils/utils';
const Pokemon = () => {
    const [isLoading, setLoading] = useState(false);
    const { name } = useParams();
    const  navigate = useNavigate();
    const [pokemon, setPokemon] = useState<PokemonDetails>();

    useEffect(() => {
        async function getPokemon() {
            setLoading(true);
            await waitFor(500);
            const fetchedPokemon = await fetchPokemon(name as string);
            setPokemon(fetchedPokemon);
            setLoading(false);
        }
        getPokemon();
    },[name]);

    if(isLoading || !pokemon) return <LoadingScreen/>;

    return (
    <>
        <button className={styles.pokeballButton} onClick={() => navigate(-1)}>
            <img className={styles.pokeballImg} src={PokeballImg} alt = ""/>
            {" "}Go Back
        </button>
        <div className={styles.pokemon}> 
            <main className={styles.pokemonInfo}>
            <div className={styles.pokemonTitle}>{name?.toUpperCase()}</div>
                <div>Nr. {pokemon?.id}</div>
                <div>
                    <img className={styles.pokemonInfoImg} 
                    src={pokemon?.imgSrc} 
                    alt={pokemon?.name} />
                </div>
                <div>HP: {pokemon?.hp}</div>
                <div>Attack: {pokemon?.attack}</div>
                <div>Defense: {pokemon?.defense}</div>
            </main>
        </div>
        <Footer/>
    </>
    );
};

export default Pokemon;
