import styles  from "./header.module.css";
type HeaderPorps = {
    query: string;
    setQuery:(query: string) => void;
}

const Header = ({ query, setQuery}: HeaderPorps) => {
    return (
        <header className={styles.header}>
            <input className={styles.header} placeholder="Busca un Pokemon" type = "text" value={query} onChange={(event) => setQuery(event.target.value)}/>
        </header>
    );
};

export default Header; 